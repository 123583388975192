<template>
  <v-container fluid full-width class="px-2" :class="bgColor">
    <v-row
      no-gutters
      v-if="links"
      justify="space-between"
      class="px-0 px-md-7 py-1"
      @click="clicked"
    >
      <v-col
        v-for="(linkColumn, index) in links"
        :key="index"
        cols="12"
        sm="3"
        class="d-flex flex-column pr-5"
      >
        <v-list :color="bgColor">
          <v-list-item-title
            class="white--text font-weight-bold mb-0 mb-sm-3"
            >{{ linkColumn.title }}</v-list-item-title
          >
          <v-list-item-group
            class="px-0"
            v-for="link in linkColumn.children"
            :key="link.ID"
          >
            <!-- :href="link.url"
            :target="link.target" -->
            <div
              v-if="link.children.length > 0"
              class="d-flex"
              style="width: 100%; gap: 10px;"
            >
              <!-- multiple items row: third level on wp menu, horizontal flex -->
              <!-- simulate btn on wp: (v-btn primary v-btn--outlined v-btn--tile) (v-btn v-btn--outlined v-btn--tile) -->
              <v-list-item
                v-for="child in link.children"
                :key="child.ID"
                class="white--text text-decoration-none text-center px-0"
                :class="child.classes"
                :href="child.url"
                :target="child.target"
              >
                <v-img
                  v-if="child.classes.includes('appstore-bg')"
                  src="/img_layout/footer/app-store.png"
                  contain
                  :max-height="$vuetify.breakpoint.sm ? 35 : 45"
                  alt="logo App Store"
                  width="130"
                />
                <v-img
                  v-else-if="child.classes.includes('gplay-bg')"
                  src="/img_layout/footer/google-play.png"
                  contain
                  :max-height="$vuetify.breakpoint.sm ? 35 : 45"
                  alt="logo Google Play"
                  width="130"
                />
                <span v-else>{{ child.title }}</span>
              </v-list-item>
            </div>
            <div v-else style="width: 100%">
              <!-- classic row: second level on wp menu -->
              <v-list-item
                class="white--text text-decoration-none py-0 px-0"
                :class="link.classes"
                :href="link.url"
                :target="link.target"
                @click="clickLink"
              >
                {{ link.title }}
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.v-list-item__title {
  font-size: 18px;
}
.v-list-item--link::before {
  background-color: transparent;
}
.v-list-item {
  min-height: 30px;
}
.v-list-item__content.v-btn {
  font-size: 12px;
  height: 35px;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "FooterBlock",
  props: {
    links: {
      type: Array,
      default: () => {
        return [];
      }
    },
    bgColor: { type: String, default: "secondary" }
  },
  mixins: [clickHandler],
  components: {},
  computed: {
    isMobile() {
      return true;
    }
  },
  methods: {
    goToExternalPage(url) {
      window.open(url, "_blank");
    },
    clickLink: function(event) {
      if (event.target.className.includes("cookiebot-widget")) {
        // eslint-disable-next-line no-undef
        Cookiebot.show();
      }
    }
  }
};
</script>

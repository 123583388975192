<template>
  <v-container fluid class="pa-0" id="footer">
    <FooterBlock :links="links" bgColor="secondary" />
    <FooterBlock :links="bottomLinks" bgColor="secondary lighten-1" />
    <v-container fluid full-width class="secondary lighten-3 py-4">
      <div class="my-0 d-flex flex-column align-center white--text">
        <i18n path="footer.info" tag="span" class="copyright text-center">
          <router-link
            class="white--text"
            :to="{
              name: 'Page',
              params: { pageName: 'contatti' }
            }"
          >
            {{ $t("footer.infoLink") }}
          </router-link>
        </i18n>
        <span class="copyright text-center">{{ $t("footer.copyright") }}</span>
        <i18n path="footer.version" tag="span" class="text-caption">
          {{ version }}
        </i18n>
      </div>
    </v-container>
  </v-container>
</template>
<style scoped lang="scss">
a {
  font-size: $font-size-root;
}
.copyright {
  font-size: 12px;
  a {
    font-size: inherit;
  }
}
</style>
<script>
import FooterBlock from "./FooterBlock.vue";

export default {
  name: "Footer",
  props: {
    links: { type: Array, required: false },
    bottomLinks: { type: Array, required: false }
  },
  components: { FooterBlock },
  computed: {
    version() {
      return global.config.version;
    },
    isMobile() {
      return true;
    }
    // copyright() {
    //   let copyright = this.$t("footer.copyright");
    //   return copyright.replace("{YEAR}", new Date().getFullYear());
    // }
  },
  methods: {
    isSocialLinksColumn(classes) {
      console.log("TODO: restituire classe socials");
      return classes.search("social") > -1;
    },
    goToExternalPage(url) {
      window.open(url, "_blank");
    }
  }
};
</script>
